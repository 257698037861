.container {
  max-width: 100%;
  width: 600px;
  display: flex;
  flex-direction: column;
}
.divNotExtracts {
  border-radius: 15px;
  border: 1px solid #a6a6a6;
  width: 90%;
  align-self: center;

  padding: 20px 20px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-size: 12px;
  }
  button {
    background-color: #569a59;
    border-radius: 15px;
    color: white;
    border: none;
    width: 200px;
    height: 40px;
    align-self: flex-end;
    margin-bottom: -15px;
  }
  button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
.divExtracts {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 25%));
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid #a6a6a6;
  width: 80%;
  padding: 10px 20px;
  padding-right: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
  }
  p {
    font-size: 12px;
  }
  button {
    background-color: #569a59;
    border-radius: 15px;
    color: white;
    border: none;
    width: 200px;
    height: 40px;
    align-self: flex-end;
    margin-bottom: -15px;
  }
  button:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
@media (max-width: 800px) {
  .divExtracts {
    width: 90%;
  }
}
