.greenContainer {
  width: 100%;
  // background: linear-gradient(92.05deg, #569a59 1.8%, #01160d 94.62%);
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: white;
}

.valuesContainer {
  text-align: center;
  display: flex;
  min-width: 340px;
  justify-content: space-between;
  h1 {
    font-size: 35px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }
  h3 {
    font-family: sans-serif;
    font-size: 18px;
  }
  align-items: center;
  img {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 25%));
  }
  svg {
    width: 30px;
    margin: 10px 0px;
  }
  svg:hover {
    cursor: pointer;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .RescueContainer {
    display: flex;
    flex-direction: row;
    button {
      background-color: #569a59;
      width: 125px;
      height: 30px;
      border-radius: 20px;
      font-weight: 400;
      margin-right: 5px;
    }
    button:disabled {
      background-color: #a6a6a6;
      cursor: not-allowed;
    }
  }
}
.perfilContainer {
  text-align: center;
  display: flex;
  min-width: 250px;
  justify-content: space-around;
  h1 {
    font-size: 35px;
    font-family: 'Roboto', sans-serif;
  }
  h3 {
    font-family: sans-serif;
    font-size: 18px;
  }
  align-items: center;
  img {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 25%));
  }
}
.mainContainer {
  min-height: 400px;
  background-color: #f5f6fa;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 0px;
  ul {
    width: 400px;
    border-radius: 12px;
    border: 2px solid #a6a6a6;
    max-width: 90%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 25%));
    background-color: #ffffff;
    margin-left: 60px;
  }
  svg {
    width: 40px;
    margin-right: 15px;
  }
  li {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    margin: 10px 0px;
  }

  li:hover {
    cursor: pointer;
   text-decoration: underline;
  }
}

@media (max-width: 800px) {
  .greenContainer {
    flex-direction: column;
    padding: 20px 0px;
  }
  .valuesContainer {
    margin: 20px 0px;
  }
  .mainContainer {
    flex-direction: column;
    ul {
      margin-bottom: 30px;
      margin-left: 0px;
    }
  }
}
