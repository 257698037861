.discount-code-view {
  background: #569a59;
  border-radius: 15px;
  min-height: 450px;
  width: 540px;
  @include media("<=phoneH") {
    width: 295px;
  }
  .discount-view-wrap {
    width: 100%;
    align-items: center;
    .discount-top {
      width: 100%;
      align-items: center;
      padding: 30px;
      .company-logo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #ffffff;
        padding: 20px;
        margin-bottom: 30px;
        @include media("<=phoneH") {
          width: 60px;
          height: 60px;
          padding: 10px;
        }
        .img-logo {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      .square {
        width: 90%;
        height: 229px;
        background: #d9d9d9;
        position: relative;
        padding: 30px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        @include media("<=phoneH") {
          padding: 18px;
          height: 180px;
        }
        &::before {
          content: "";
          border-radius: 50%;
          width: 50px;
          height: 50px;
          position: absolute;
          top: 42%;
          left: -25px;
          @include media("<=phoneH") {
            width: 25px;
            height: 25px;
            left: -12px;
          }
        }
        &::after {
          content: "";
          border-radius: 50%;
          width: 50px;
          height: 50px;
          position: absolute;
          top: 42%;
          right: -25px;
          @include media("<=phoneH") {
            width: 25px;
            height: 25px;
            right: -12px;
          }
        }
        .square-tag {
          font-weight: 700;
          font-size: 22px;
          line-height: 33px;
          text-align: center;
          color: #3a3a3a;
          margin: 30px 0;
          @include media("<=phoneH") {
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            margin: 16px 0;
          }
        }
        .separator {
          width: 100%;
          border-bottom: 2px dashed #000000;
          margin-top: 20px;
          margin-bottom: 26px;
          @include media("<=phoneH") {
            margin-top: 18px;
            margin-bottom: 16px;
          }
        }
        .valid-val {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          text-align: center;
          color: #838383;
          bottom: 0;
          @include media("<=phoneH") {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
    .discount-btm {
      min-height: 100px;
      width: 100%;
      padding: 30px;
      background: #eaeaea;
      border-radius: 0 0 15px 15px;
      .actions {
        .btn-code {
          width: 250px;
          background: #ffffff;
          border: 2px dashed #379c44;
          border-radius: 45px;
          font-weight: 600;
          font-size: 16px;
          line-height: 30px;
          text-align: center;
          letter-spacing: 0.28em;
          color: #379c44;
          padding: 6px 28px;
        }
        .copy-btn {
          width: 250px;
          cursor: pointer;
          padding: 6px 20px;
          background: #569a59;
          border-radius: 30px;
          margin-top: 20px;
          .btn-icon {
            margin-right: 12px;
            svg {
              height: 26px;
              width: 26px;
            }
          }
          .btn-lbl {
            font-weight: 500;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }
}
