.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
  h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 30px;
    max-width: 90%;
    text-align: center;
    margin: 10px 0px;
  }
  ul {
    max-width: 90%;
  }
  li {
    margin: 15px 0px;
    max-width: 90%;
  }
  h3 {
    max-width: 90%;
    margin: 20px 0px;
  }
  span {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 20px;
  }
}
