.containerModal {
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 95vw !important;
  width: 400px;

  img {
    width: 150px;
    margin: 20px 0px;
  }
  text-align: center;
  button {
    width: 200px;
    height: 40px;
    background: #569a59;
    border-radius: 20px;
    color: white;
    margin: 20px 0px;
    padding-top: 3px;
  }
  button:hover {
    opacity: 0.8;
  }
  div {
    width: 100%;
    color: white;
    background-color: #569a59;
    padding: 20px;
  }
  h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 18px;
    margin: 10px 0px;
  }
  h3{
    font-size: 14px;
  }
  span{
    margin: 0px 0px 20px 0px;
    background-color: #569a59;
    height: 2px;
    width: 90%;
  }
  h1{
    font-weight: 600;
    font-size: 24px;
  }
  h4{
    font-size: 13px;
  }
}
