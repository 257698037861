@import "./Colors.scss";
@import "./Props.scss";
@import "./media.scss";
@import "../components/Header/style.scss";
@import "../components/Footer/style.scss";
@import "../components/Sidebar/style.scss";
@import "../Pages/Home/style.scss";
@import "../Pages/Login/style.scss";
@import "../components/SearchBox/search-style.scss";
@import "../Pages/CompanyDetail/company-detail.scss";
@import "../components/DiscountCodeView/discount-code-style.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

// Mixin for font family
@mixin roboto($weight) {
  font-family: 'Roboto', sans-serif;
  font-weight: $weight;
}

// Classes for different weights
.bold {
  @include roboto(700);
}

.normal {
  @include roboto(400);
}

@font-face {
  font-family: "Nexa Black";
  src: url("../assets/fonts/NexaBlack.otf");
}
@font-face {
  font-family: "Nexa Bold";
  src: url("../assets/fonts/NexaBold.otf");
}
@font-face {
  font-family: "Nexa Book";
  src: url("../assets/fonts/NexaBook.otf");
}
@font-face {
  font-family: "Nexa ExtraBold";
  src: url("../assets/fonts/NexaXBold.otf");
}
@font-face {
  font-family: "Nexa ExtraLight";
  src: url("../assets/fonts/Nexa\ Light.otf");
}

@font-face {
  font-family: "Nexa Regular";
  src: url("../assets/fonts/NexaRegular.otf");
}
.font-regular {
  font-family: "Nexa Regular";
}

@font-face {
  font-family: "Nexa Light";
  src: url("../assets/fonts/NexaLight.otf");
}

@font-face {
  font-family: "Nexa Heavy";
  src: url("../assets/fonts/NexaHeavy.otf");
}
.font,
body {
  background: #f7f7f7;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin bgImage($radius: 50%) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #e2e2e2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

@mixin dropDownAnim($duration: 0.3s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}

@keyframes drop {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
}

.wrapWidth {
  width: 1340px;
  margin: 0 auto;
  @include media("<=xlarge") {
    width: 1260px;
    margin: 0 auto;
  }
  @include media("<=large") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=desktop") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=tablet") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media(">=phone", "<=480px") {
    padding: 0px 5%;
  }
}

.App {
  min-height: 100vh;
}

//Drop Down
.dropDown {
  width: 100%;
  .category {
    width: 100%;
    .cbox {
      // background: #000403;
      border: 1px solid #bababa;
      padding: 0 16px;
      min-height: 42px;
      border-radius: 5px;
      box-sizing: border-box;
      min-width: 180px;
      width: 100%;
      justify-content: space-between;
      @include media(">=phone", "<=480px") {
        min-width: 150px;
      }
      .icon-arrow {
        svg {
          path {
            fill: $themeColor;
          }
        }
      }
      .slt {
        overflow: hidden;
        .ico {
          cursor: pointer;
          color: #000000d9;
          height: 22px;
          display: flex;
          align-items: center;
        }
        .lbl {
          color: #000;
          padding: 0 10px;
        }
        .unit-name {
          .unit-eng {
            color: #000000;
          }
        }
      }
      .d-img {
        margin: 2px 10px;
        .img {
          height: 25px;
          width: 25px;
          padding: 2.5px;
          border-radius: 100%;
          border: 1px solid $themeColor;
        }
      }
      .edit-icon {
        margin: 2px 10px;
        padding: 4px;
        border-radius: 100%;
        border: 1px solid $themeColor;
      }
      .dropDown-icon {
        cursor: pointer;
      }
    }
  }
  .block {
    top: 45px;
    left: 0;
    right: 0;
    border-radius: 4px;
    border: 1px solid $themeColor;
    background: #000403;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    width: 100%;
    .manue {
      width: 100%;
      // height: 150px;
      overflow-y: auto;
      padding: 0px 0px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      .slt {
        width: 100%;
        padding: 8px 16px;
        @include anim;
        &.active {
          background-color: $themeColor;
          color: #242424;
          .unit-name {
            .unit-eng {
              color: #242424;
            }
          }
        }
        .unit-name {
          .unit-eng {
            color: #eee;
          }
        }
        .ico {
          justify-content: flex-start;
          margin-right: 8px;
          width: 25px;
          height: 25px;
        }
        .lbl {
          flex: 1;
          margin-left: 5px;
          justify-content: flex-start;
        }
        &:hover {
          background-color: #1e4231;
          cursor: pointer;
          .ico {
            color: $themeColor;
          }
          .lbl {
            color: $themeColor;
          }
        }
      }
    }
    &.show {
      display: flex;
      visibility: visible;
      opacity: 1;
      @include dropDownAnim;
    }
  }
}

// Loader
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-cmp {
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  
  z-index: 9999999999;
  overflow: hidden;
  position: absolute;
  inset: 0;
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #444444;
      border-color: #444444 transparent #444444 transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

// Dashboard
.dashboard-page {
  min-height: 730px;
  .wrap {
  }
}
