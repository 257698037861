.lading-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  .wrap {
    .top-swiper {
      .swiper-pagination {
        display: none !important;
      }
      .swiper {
        height: 100%;
        .swiper-wrapper {
          height: 240px;
          .swiper-slide {
            background: transparent;
            @include media("<=phoneH") {
            }
            .slider-card {
              height: 185px;
              width: 100%;
              position: relative;
              .card-bg {
                height: 100%;
                width: 100%;
                border-radius: 8px;
                background-color: #e2e2e2;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                background-image: url("https://clubecerto.com.br/cashback/images/Banner_Cashback_Nike.png");
                position: relative;
                .card-logo {
                  position: absolute;
                  top: -22px;
                  left: 90px;
                  width: 116px;
                  height: 46px;
                  background: #d9d9d9;
                  border-radius: 6px;
                  background: #ffffff;
                  filter: drop-shadow(
                    1.48496px 3.71239px 2.96991px rgba(0, 0, 0, 0.25)
                  );
                  .logo-img {
                    width: 90px;

                    object-fit: contain;
                  }
                }
                .card-meta {
                  // background: #006341;
                  width: 150px;
                  height: 100%;
                  padding: 12px 16px;
                  border-radius: 8px 0 0 8px;
                  text-align: start;

                  .until-lbl {
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 95.84%;
                    color: #ffffff;
                  }
                  .numb {
                    font-weight: 800;
                    font-size: 42px;
                    letter-spacing: -0.07em;
                    color: #ffffff;
                    margin-top: 7px;
                  }
                  .cash-back {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #ffffff;
                  }
                  .btn-login {
                    background: #1b1b1b;
                    border-color: #1b1b1b;
                    border-radius: 32px;
                    display: flex !important;
                    padding: 4px 4px;
                    margin-top: 8px;
                    .btn-icon {
                      svg {
                        height: 18px;
                        width: 18x;
                      }
                    }
                    .btn-lbl {
                      color: #ffffff;
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 19px;
                      margin-top: 5px;
                    }
                  }
                }
              }
            }
            &:nth-child(2) {
              .card-bg {
                background-image: url("https://clubecerto.com.br/cashback/images/Banner_Cashback_Mac.png");

                .card-logo {
                  background: #000;
                  .logo-img {
                    height: 38px;
                  }
                }
              }
            }
            &:nth-child(3) {
              .card-bg {
                background-image: url("https://clubecerto.com.br/cashback/images/slider3.png");
                .card-logo {
                  background: #ffffff;
                  .logo-img {
                    height: 34px;
                    width: 90px;
                    object-fit: contain;
                  }
                }
                .card-meta {
                  background: none;
                }
              }
            }
            &:nth-child(4) {
              .card-bg {
                background-image: url("https://clubecerto.com.br/cashback/images/slider4.png");
                .card-logo {
                  .logo-img {
                    height: 54px;
                    width: 80px;
                    object-fit: contain;
                  }
                }
                .card-meta {
                  background: none;
                }
              }
            }
            &:nth-child(5) {
              .card-bg {
                background-image: url("https://clubecerto.com.br/cashback/images/slider5.png");
                .card-logo {
                  background: #ff6914;
                  .logo-img {
                    height: 50px;
                    width: 55px;
                    object-fit: contain;
                  }
                }
                .card-meta {
                  background: none;
                }
              }
            }
            &:nth-child(6) {
              .card-bg {
                background-image: url("https://clubecerto.com.br/cashback/images/slider6.png");
                .card-logo {
                  background: black;
                  .logo-img {
                    height: 64px;
                    width: 66px;
                    object-fit: contain;
                  }
                }
                .card-meta {
                  background: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .separator {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
    border-bottom: 2px solid #d8d8d8;
  }
  .sec-meta {
    .discount-tag {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 44px;
      color: #569a59;
    }
    .discount-desc {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 31px;
      color: #1b1b1b;
    }
  }
  .wrap2 {
    .categories-swiper {
      margin: 50px 0;
      .swiper-pagination {
        display: none !important;
      }
      .swiper {
        height: 100%;
        .swiper-wrapper {
          height: 140px;
          .swiper-slide {
            background: transparent;
            .slider-card-category {
              height: 100%;
              width: 100%;
              background: #d9d9d9;
              border-radius: 15px;
              overflow: hidden;
              .category-card-icon {
                height: 100%;
                width: 100%;
                padding: 20px;
                .icon {
                  height: 54px;
                  width: 54px;
                }
              }
              .category-card-name {
                height: 86px;
                width: 100%;
                font-weight: 600;
                font-size: 20px;
                line-height: 36px;
                text-align: center;
                color: #ffffff;
                background: #379c44;
              }
            }
          }
        }
      }
    }
    .companies-list {
      margin: 30px 0;
      padding: 0 8%;
      width: 100%;
      grid-gap: 30px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      @include media("<=desktop") {
        padding: 0 5%;
        grid-template-columns: repeat(4, 1fr);
      }
      @include media("<=tablet") {
        padding: 0 3%;
        grid-template-columns: repeat(3, 1fr);
      }
      @include media("<=phoneH") {
        padding: 0;
        grid-template-columns: repeat(1, 1fr);
      }
      .company-card {
        height: 220px;
        width: 100%;
        background: #e6e6e6;
        border-radius: 16px;
        overflow: hidden;
        cursor: pointer;
        border: 2px solid transparent;
        @include anim;
        &:hover {
          border-color: $themeColor;
        }
        .company-info {
          padding: 20px;
          .company-logo {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: #d9d9d9;
            padding: 10px;
            .c-logo {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
          .company-name {
            color: #737373;
            font-weight: 600;
            font-size: 12px;
            margin: 8px 0;
          }
        }
        .company-discount {
          width: 100%;
          height: 45px;
          color: #379c44;
          font-weight: 800;
          font-size: 16px;
          background: #000000;
          border-radius: 0px 0px 15px 15px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          .disc-tag {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .wrap3 {
    .deals-swiper {
      margin: 40px 0;
      .swiper {
        height: 100%;
        .swiper-wrapper {
          height: 510px;
          .swiper-slide {
            height: 510px;
            background: transparent;
            .deal-card {
              height: 450px;
              width: 100%;
              background: #f1f1f1;
              border-radius: 12px;
              position: relative;
              .deal-action {
                top: -20px;
                width: 100%;
                position: absolute;
                .btn {
                  padding: 8px;
                  font-size: 12px;
                  line-height: 22px;
                  color: #ffffff;
                }
              }
              .card-top-part {
                flex: 1;
                padding: 20px;
                .company-logo {
                  height: 130px;
                  width: 100%;
                  background: #d9d9d9;
                  border-radius: 20px;
                  margin-top: 30px;
                  margin-bottom: 12px;
                  .logo-img {
                    height: 60px;
                    width: 60px;
                    object-fit: contain;
                  }
                }
                .company-info {
                  text-align: start;

                  .c-name {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22px;
                    color: #1c1c1c;
                  }
                  .c-desc {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    display: flex;
                    align-items: center;
                    color: #1c1c1c;
                  }
                }
                .c-separator {
                  margin: 12px 0;
                  border-bottom: 2.3px solid #a1a1a1;
                }
                .price-desc {
                  text-align: center;
                  .icon {
                    height: 30px;
                    width: 30px;
                    margin-bottom: 10px;
                    .icon-img {
                      height: 100%;
                      width: 100%;
                      object-fit: contain;
                    }
                  }
                  .price-dec {
                    font-weight: 400;
                    font-size: 16.0617px;
                    line-height: 19px;
                    color: #1c1c1c;
                    text-align: start;
                    height: 75px;
                    overflow: hidden;
                    overflow-y: auto;
                  }
                }
              }
              .card-btm-part {
                height: 100px;
                width: 100%;
                .vail-sec {
                  height: 50px;
                  width: 100%;
                  background: #e8e8e8;
                  border-radius: 12px 12px 0 0;
                  .valid-tag {
                    color: #797979;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    margin-top: 5px;
                    span {
                      color: #1c1c1c;
                      font-weight: 700;
                      font-size: 16px;
                    }
                  }
                }
                .discount-code-sec {
                  height: 50px;
                  width: 100%;
                  background: $themeColor;
                  border-radius: 0 0 12px 12px;
                  .code-dec {
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    color: #ffffff;
                    margin-top: 6px;
                  }
                }
              }
            }
          }
        }
        .swiper-button-prev {
          left: 0px;
          height: 40px;
          width: 40px;

          border-radius: 50%;
          padding: 20px;
          &::after {
            color: $white;
            font-size: 20px;
            font-weight: 800;
          }
        }
        .swiper-button-next {
          right: 0px;
          height: 40px;
          width: 40px;

          border-radius: 50%;
          padding: 20px;
          &::after {
            color: $white;
            font-size: 20px;
            font-weight: 800;
          }
        }
      }
    }
  }
  .wrap4 {
    .faq-sec {
      margin-top: 50px;
      gap: 40px;
      @include media("<=tablet") {
        flex-direction: column;
      }
      .faq-left {
        flex: 1;
      }
      .faq-right {
        flex: 1;
      }
      .faq-item {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
        .faq-row {
          cursor: pointer;
          padding: 12px 12px;
          .arrow-icon {
            svg {
              height: 26px;
              width: 26px;
            }
          }
          .question {
            font-weight: 400;
            font-size: 20px;
            line-height: 31px;
            color: #1b1b1b;
          }
        }
        .answer {
          padding: 6px 12px;
          display: none;
          font-weight: 400;
          font-size: 20px;
          line-height: 31px;
          color: $themeColor;
          margin-top: 6px;
          &.show {
            display: flex;
          }
        }
      }
    }
  }
}
