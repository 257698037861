// Header
.header-camp {
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 90px;
  padding: 12px 0px;
  position: sticky;
  background: #ffffff;
  border-bottom: 2px solid #bababa;
  .wrap {
    gap: 30px;
   
    .left {
      flex: 0.2;
      .logo-img {
        .logo {
        }
      }
    }
    .center {
      flex: 0.8;
      margin: 0 20px;
    }
    .right {
      flex: 1;
      @include media("<=phoneH") {
        flex-direction: column;
      }
      .actions {
        .btn-register {
          border-radius: 30px;
          padding: 6px 20px;
          margin-right: 20px;
        }
        .btn-login {
          padding: 6px 20px;
          border-radius: 30px;
        }
      }
      .menus-block {
        @include media("<=phoneH") {
          margin-top: 20px;
        }
        .menu-box {
          margin-left: 38px;
          .m-left {
            .name {
              color: #bebebe;
              font-weight: 400;
              font-size: 16px;
            }
            .designation {
              color: #8a8a8a;
              font-weight: 400;
              font-size: 22px;
            }
          }
          .m-right {
            cursor: pointer;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
.perfilImg{
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 25%));
}

