// Footer
.footer-comp {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 200px;
  padding: 50px 0px;
  border-top: 2px solid #d8d8d8;
  .wrap {
    gap: 40px;
    @include media("<=tablet") {
      flex-direction: column;
    }
    .footer-links {
      .link-tag {
        font-weight: 700;
        font-size: 20px;
        color: #1b1b1b;
        margin-bottom: 6px;
      }
      .link-item {
        font-weight: 500;
        font-size: 16px;
        color: #379c44;
        margin-bottom: 4px;
        cursor: pointer;
        @include anim;
        &:hover {
          color: #1b1b1b;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .left {
      flex: 1;
      height: 100%;
      @include media("<=tablet") {
        width: 100%;
      }
    }
    .center {
      flex: 0.7;
      height: 100%;
      @include media("<=tablet") {
        flex: 1;
      }
    }
    .right {
      flex: 1.3;
      height: 100%;
      @include media("<=tablet") {
        flex: 1;
        width: 100%;
      }
      .logos {
        @include media("<=tablet") {
          flex-direction: column;
          width: 100%;
        }
        .logo2 {
          @include media("<=desktop") {
            height: 50px;
          }
        }
        .logo3 {
          @include media("<=desktop") {
            // height: 70px;
          }
        }
        .separator {
          margin: 0 30px;
          height: 100%;
          border-right: 3px solid #d9d9d9;
          @include media("<=tablet") {
            border-right: 0;
            width: 100%;
            margin: 30px 0;
            border-bottom: 3px solid #d9d9d9;
          }
        }
      }
    }
  }
}
