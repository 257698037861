.search-component {
  width: 100%;
  height: 50px;
  min-width: 300px;
  background: #ebebeb;
  border-radius: 32px;
  padding: 8px 18px;
  border: 2px solid transparent;
  @include anim;
  &:focus-within {
    border-color: $themeColor;
  }
  .txt {
    margin-top: 6px;
    height: 100%;
    color: #949494;
  }
  .search-icon {
  }
}
