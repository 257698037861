.formSettings {
  max-width: 95%;
  width: 700px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 10px 15px;
  border: 2px solid #a6a6a6;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 25%));
  background-color: #ffffff;

  h1,
  h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }
  h1 {
    font-size: 20px;
    text-align: center;
  }
  input,
  select {
    width: 100%;
    height: 35px;
    padding: 0px 10px;
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.2);
    margin-bottom: 5px;
    padding-left: 40px;
  }
  svg {
    position: absolute;
    bottom: 10px;
  }
}

.defaultButton {
    margin:10px 0px;
  width: 180px;
  height: 40px;
  color:white;
  background: #569a59;
  border-radius: 46px;
  align-self: center;
  &:hover{
    opacity: 0.8;
    cursor: pointer;
  } 
}
