.company-detail-page {
  min-height: 730px;
  background: #ffffff;
  .wrap {
    .page-route {
      margin: 30px 0;
      .icon {
        margin-right: 5px;
        svg {
          height: 30px;
          width: 30px;
        }
      }
      .current-page-name {
        font-weight: 400;
        font-size: 20px;
        line-height: 31px;
        color: #000000;
        margin-top: 6px;
      }
    }
    .page-top-sec {
      margin: 50px 0;
      padding: 0 8%;
      width: 100%;
      .top-sec-box {
        width: 100%;
        height: 272px;
        background: #f3f3f3;
        border-radius: 12px;
        padding: 30px 40px;
        @include media("<=tablet") {
          flex-direction: column;
          height: 100%;
        }
        .box-left-side {
          @include media("<=tablet") {
            margin-bottom: 30px;
          }
          .item {
            .company-logo {
              width: 120px;
              height: 120px;
              border-radius: 50%;
              background: #d9d9d9;
              .img-logo {
              }
            }
            .company-name {
              font-weight: 600;
              font-size: 22px;
              color: #569a59;
              margin-top: 20px;
            }
          }
        }
        .box-right-side {
          .item {
            .amount {
              font-weight: 800;
              font-size: 56px;
              color: #000000;
              line-height: 1;
            }
            .amount-lbl {
              font-weight: 600;
              font-size: 20px;
              color: #000000;
            }
            .action {
              margin-top: 40px;
              .btn {
                padding: 6px 16px;
                border-radius: 66px;
              }
            }
          }
        }
      }
    }
    .page-center-sec {
      margin: 30px 0;
      width: 100%;
      grid-gap: 80px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @include media("<=tablet") {
        grid-gap: 50px;
        grid-template-columns: repeat(1, 1fr);
      }
      .detail-card {
        width: 100%;
        height: 246px;
        background: #f8f8f8;
        border: 2px solid #a6a6a6;
        border-radius: 15px;
        padding: 40px 20px;
        .card-t {
          .card-lbl {
            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
            color: #1c1c1c;
          }
          .icon {
            svg {
              height: 30px;
              width: 30px;
            }
          }
        }
        .card-dec {
          margin-top: 30px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #1c1c1c;
        }
      }
    }
    .page-btm {
      width: 100%;
      margin-bottom: 60px;
      .btm-box {
        width: 100%;
        height: 246px;
        background: #f8f8f8;
        border: 2px solid #a6a6a6;
        border-radius: 15px;
        padding: 30px 40px;
        @include media("<=tablet") {
          flex-direction: column;
          height: 100%;
          padding: 10px;
        }
        .meta-side {
          @include media("<=tablet") {
            margin-bottom: 30px;
            .desc {
              text-align: center;
              width: 99%;
            }
          }
          .offer-tag {
            font-weight: 800;
            font-size: 36px;
            line-height: 44px;
            color: #569a59;
          }
          .back-amount {
            font-weight: 800;
            color: #1c1c1c;
          }
          .desc {
            font-family: 'Roboto', sans-serif;
            margin-top: 30px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #1c1c1c;
            width: 95%;
          }
        }
        .action {
          .btn {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: #ffffff;
            border-radius: 66px;
          }
        }
      }
    }
  }
}

.wrap3 {
  .deals-swiper {
    margin: 40px 0;
    .swiper {
      height: 100%;
      .swiper-wrapper {
        height: 510px;
        .swiper-slide {
          height: 510px;
          background: transparent;
          .deal-card {
            height: 450px;
            width: 100%;
            background: #f1f1f1;
            border-radius: 12px;
            position: relative;
            .deal-action {
              top: -20px;
              width: 100%;
              position: absolute;
              .btn {
                padding: 8px;
                font-size: 12px;
                line-height: 22px;
                color: #ffffff;
              }
            }
            .card-top-part {
              flex: 1;
              padding: 20px;
              .company-logo {
                height: 130px;
                width: 100%;
                background: #d9d9d9;
                border-radius: 20px;
                margin-top: 30px;
                margin-bottom: 12px;
                .logo-img {
                  height: 60px;
                  width: 60px;
                  object-fit: contain;
                }
              }
              .company-info {
                text-align: start;

                .c-name {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 22px;
                  color: #1c1c1c;
                }
                .c-desc {
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 14px;
                  display: flex;
                  align-items: center;
                  color: #1c1c1c;
                }
              }
              .c-separator {
                margin: 12px 0;
                border-bottom: 2.3px solid #a1a1a1;
              }
              .price-desc {
                text-align: center;
                .icon {
                  height: 30px;
                  width: 30px;
                  margin-bottom: 10px;
                  .icon-img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                  }
                }
                .price-dec {
                  font-weight: 400;
                  font-size: 16.0617px;
                  line-height: 19px;
                  color: #1c1c1c;
                  text-align: start;
                  height: 60px;
                  overflow: hidden;
                  overflow-y: auto;
                }
              }
            }
            .card-btm-part {
              height: 100px;
              width: 100%;
              .vail-sec {
                height: 50px;
                width: 100%;
                background: #e8e8e8;
                border-radius: 12px 12px 0 0;
                .valid-tag {
                  color: #797979;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 19px;
                  margin-top: 5px;
                  span {
                    color: #1c1c1c;
                    font-weight: 700;
                    font-size: 16px;
                  }
                }
              }
              .discount-code-sec {
                height: 50px;
                width: 100%;
                background: $themeColor;
                border-radius: 0 0 12px 12px;
                .code-dec {
                  font-weight: 800;
                  font-size: 16px;
                  line-height: 22px;
                  text-align: center;
                  color: #ffffff;
                  margin-top: 6px;
                }
              }
            }
          }
        }
      }
      .swiper-button-prev {
        left: 0px;
        height: 40px;
        width: 40px;

        border-radius: 50%;
        padding: 20px;
        &::after {
          color: $white;
          font-size: 20px;
          font-weight: 800;
        }
      }
      .swiper-button-next {
        right: 0px;
        height: 40px;
        width: 40px;

        border-radius: 50%;
        padding: 20px;
        &::after {
          color: $white;
          font-size: 20px;
          font-weight: 800;
        }
      }
    }
  }
}
.discount-tag {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 44px;
  color: #569a59;
  text-align: center;
}
.discount-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  color: #1b111b;
  text-align: center;
}
